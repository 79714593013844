import React from 'react'
import { graphql } from 'gatsby'
import { Box } from 'rebass'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Container from '../../components/container'
import Content from '../../components/content'
import Pagetitle from '../../components/pagetitle'

const AssistenzaPointPage = ({ data }) => {
  const { frontmatter, html } = data.allMarkdownRemark.edges[0].node
  const { title } = frontmatter

  return (
    <Layout>
      <SEO title={title} />
      <Pagetitle>{title}</Pagetitle>
      <Container>
        <Box pb={4} pt={4}>
          <Content dangerouslySetInnerHTML={{ __html: html }} />
        </Box>
      </Container>
    </Layout>
  )
}

export const assistenzaPointPageQuery = graphql`
  query AssistenzaPointPage {
    allMarkdownRemark(
      filter: { fields: { slug: { eq: "/servizi/assistenza-point/" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          html
        }
      }
    }
  }
`

export default AssistenzaPointPage
